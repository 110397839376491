import { StoreState } from "../../../Types";
import { MouseTooltipState } from "../types";

/**
 * Selector to retrieve tooltips from the Redux store.
 *
 * @param state - The root state of the Redux store.
 * @param position - (Optional) The position of the tooltip to retrieve ('top-left' | 'top-right').
 * @returns An array of all active tooltips if no position is provided,
 *          or a single tooltip corresponding to the specified position.
 */
export const selectMouseTooltips = (
  state: StoreState,
): MouseTooltipState | undefined => {
  return state.editorUI.mouseMouseTooltips;
};
