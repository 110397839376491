import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../initialState";
import { addMouseTooltip } from "./mouseTooltip";

export const edtiorUISlice = createSlice({
  name: "edtiorUI",
  initialState,
  reducers: {
    addMouseTooltip,
  },
});

export default edtiorUISlice.reducer;
