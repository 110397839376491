import { loadStateFromLocalStorage } from "../../../app/Reducers/AppConfig";
import DefaultService from "../Default";
const COLLECTION = "shopify";

class ShopifyService extends DefaultService {
  private enableShopId: boolean;

  /**
   * @param enableShopId - When true, the service will look up 'selectedShopId' from localStorage
   *                     and pass it in the headers as 'selectShopID'. Defaults to false.
   */
  constructor(enableShopId: boolean = false) {
    super(COLLECTION);
    this.enableShopId = enableShopId;
  }

  /**
   * Helper method to generate request headers.
   * If enableShopId is true, it will fetch the shop id from localStorage.
   * Throws an error if the shop id is enabled but not found.
   */
  private getHeaders(): Record<string, string> {
    const headers: Record<string, string> = {};
    if (this.enableShopId) {
      const appconfig = loadStateFromLocalStorage();
      if (!appconfig) {
        throw new Error("App config not found in localStorage");
      }
      const shopId = appconfig["selectedShopifyShopId"];
      if (!shopId) {
        throw new Error("selectedShopId not found in localStorage");
      }
      headers["selectshopid"] = shopId;
    }
    return headers;
  }

  async get_data(endPoint: string): Promise<any> {
    const headers = this.getHeaders();

    const res = await this.fetchJson<any>(endPoint, {
      method: "GET",
      headers,
    });
    return res;
  }

  async post_data(endPoint: string, body: any = []) {
    const headers = this.getHeaders();
    const res = await this.fetchJson<any>(endPoint, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    return res;
  }

  async put_data(endPoint: string, body: any = []) {
    const headers = this.getHeaders();
    const res = await this.fetchJson<any>(endPoint, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    });
    return res;
  }
}

export default ShopifyService;
