import { configureStore } from "@reduxjs/toolkit";

import allStageDataListReducer from "../Reducers/AllStagesReducer";
import APPConfigDataReducer from "../Reducers/AppConfig";
import currentStageReducer from "../Reducers/CurrentStageReducer";
import editorFontsReducer from "../Reducers/FontReducer";
import logoStageDataReducer from "../Reducers/LogoStage";
import stageDataReducer from "../Reducers/StageReducer";
import tutorialsReducer from "../Reducers/TutorialsReducer";
import { StoreState } from "../Types";
import editorConfigDataReducer from "../Reducers/EditorConfig";
import { editorUIReducer } from "../Reducers/EditorUI";

const configureKonvaEditorStore = (preloadedState?: StoreState) => {
  const store = configureStore({
    reducer: {
      stageDataList: stageDataReducer,
      currentStageData: currentStageReducer,
      logoStageData: logoStageDataReducer,
      allStageDataList: allStageDataListReducer,
      editorFonts: editorFontsReducer,
      tutorial: tutorialsReducer,
      appConfig: APPConfigDataReducer,
      editorConfigDataReducer: editorConfigDataReducer,
      editorUI: editorUIReducer,
    },
    // Remove later
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Disable the serializability check
      }),
  });
  return store;
};

export default configureKonvaEditorStore;
