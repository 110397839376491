import React from "react";

export interface DropdownItemInterface {
  label: string;
  onClick?: () => void;
  icon?: string;
}

export interface DropdownInterface {
  items: DropdownItemInterface[];
  username?: string;
  dropdownOutsideClickRef: React.RefObject<HTMLDivElement>;
}

const Dropdown: React.FC<DropdownInterface> = ({
  items,
  username,
  dropdownOutsideClickRef,
}) => {
  return (
    <div
      ref={dropdownOutsideClickRef}
      className="absolute right-0 z-50 mt-2 w-auto min-w-[150px] origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:w-48"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
    >
      {username && (
        <div className="border-b px-4 py-2 text-sm text-gray-700">
          Logged in as <span className="font-medium">{username}</span>
        </div>
      )}
      <div className="py-1">
        {items.map((item, index) => (
          <button
            key={index}
            onClick={item.onClick}
            className="flex w-full items-center gap-2 px-4 py-2 text-sm text-gray-700 transition-colors duration-150 ease-in-out hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
            role="menuitem"
          >
            {item.icon && <img src={item.icon} alt="" className="h-5 w-5" />}
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
