import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  APPConfigAction,
  selectCurrentBusinessMode,
} from "../../app/Reducers/AppConfig";
import { EBusinessType } from "../../app/Reducers/AppConfig/types";
import ProcessingLoading from "../../components/Loader/Processing";
import { useOpenState } from "../../hooks/useOpenState";
import ConnectionsService from "../../utils/Services/Connections";
import UserService from "../../utils/Services/User";
import { toastifyGeneralTheme } from "../../utils/ToastifyGeneral";
import { IShopifyStore } from "../Integration/Modals/Shopify";
import ShopifyService from "../../utils/Services/Shopify";

interface OnboardingContextType {
  isOpen: { [key: string]: boolean };
  userConnections: UserConnectionsInterface[];
  handleIsOpen: (key: string, value: boolean) => void;
  handleOpenClose: (key: string) => void;
  isServiceConnected: (key: string) => boolean;
  handleFinishTutorial: () => void;
  checkServices: (services: string[]) => boolean;
  shopifyStores: IShopifyStore[] | undefined;
}

interface UserConnectionsInterface {
  service: string;
  isConnected: boolean;
}

const OnboardingContext = createContext<OnboardingContextType | null>(null);

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      "useOnboardingContext must be used within an OnboardingProvider",
    );
  }
  return context;
};

export const onboardingPages = {
  [EBusinessType.POD]: "/onboarding/pod",
  [EBusinessType.BRANDED]: "/onboarding/branded",
  [EBusinessType.DROPSHIPPING]: "/onboarding/dropshipping",
};
const shopifyService = new ShopifyService();
const OnboardingInitial: React.FC = () => {
  const [isOpen, handleIsOpen, handleOpenClose] = useOpenState({
    printify: false,
    shopify: false,
    isLoading: true,
  });
  const currentBusinessType = useSelector(selectCurrentBusinessMode);
  const navigate = useNavigate();
  const userService = new UserService();
  const connectionService = new ConnectionsService();
  const [userConnections, setUserConnections] = useState<
    UserConnectionsInterface[]
  >([]);
  const dispatch = useDispatch();
  const [shopifyStores, setShopifyStores] = useState<IShopifyStore[]>();
  useEffect(() => {
    if (!currentBusinessType) {
      setShopifyStores([]);
      return;
    }
    shopifyService
      .get_data(`/myShops?businessType=${currentBusinessType}`)
      .then((res) => {
        setShopifyStores(res);
      })
      .catch((err) =>
        toast.error("Failed to get the shops!", { ...toastifyGeneralTheme }),
      )
      .finally(() => handleIsOpen("shopifyShops", false));
  }, [currentBusinessType]);
  const handleChangeBusinessType = (businessType: EBusinessType) => {
    dispatch(APPConfigAction.changeMode(businessType));
  };

  useEffect(() => {
    if (currentBusinessType !== null) {
      const userStr = localStorage.getItem("user");
      if (userStr) {
        try {
          const user = JSON.parse(userStr);
          if (user?.[currentBusinessType]) {
            navigate("/");
          } else {
            navigate(onboardingPages[currentBusinessType]);
          }
        } catch (error) {
          console.error("Failed to parse user data from localStorage:", error);
          navigate(onboardingPages[currentBusinessType]); // Fallback in case of an error
        }
      } else {
        navigate(onboardingPages[currentBusinessType]);
      }
    }
  }, [currentBusinessType, navigate]);

  useEffect(() => {
    const fetchData = () => {
      if (!currentBusinessType) return;

      connectionService
        .get_data(`?businessType=${currentBusinessType}`)
        .then((res) => setUserConnections(res.services))
        .catch((err) =>
          toast.error(`Failed to get the connection statuses`, {
            ...toastifyGeneralTheme,
          }),
        )
        .finally(() => handleIsOpen("isLoading", false));
    };

    // Call the fetchData immediately on mount
    fetchData();

    // Set up the interval to call fetchData every 10 seconds
    const intervalId = setInterval(fetchData, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentBusinessType]);

  const markTutorialAsViewed = () => {
    const userStr = localStorage.getItem("user");
    if (userStr) {
      try {
        const user = JSON.parse(userStr);

        if (currentBusinessType) {
          user[currentBusinessType] = true;
          localStorage.setItem("user", JSON.stringify(user));
        } else {
          console.warn(
            "Current business type is null. Cannot mark tutorial as viewed.",
          );
        }
      } catch (error) {
        console.error("Failed to parse user data from localStorage:", error);
      }
    } else {
      console.warn("No user data found in localStorage.");
    }
  };

  const isServiceConnected = (serviceName: string): boolean => {
    const service = userConnections.find(
      (connection) => connection.service === serviceName,
    );
    return service ? service.isConnected : false;
  };

  const handleFinishTutorial = () => {
    if (!currentBusinessType) {
      toast.error("Business type is not selected.", {
        ...toastifyGeneralTheme,
      });
      return;
    }

    handleIsOpen("isLoading", true);
    userService
      .put_data("/onboarding", { [currentBusinessType]: true })
      .then(() => {
        markTutorialAsViewed();
        navigate("/storeBuilder"); // Use navigate instead of window.location.replace for SPA routing
      })
      .catch((err) =>
        toast.error(`Failed to end the tutorial`, {
          ...toastifyGeneralTheme,
        }),
      )
      .finally(() => handleIsOpen("isLoading", false));
  };

  function checkServices(servicesRequired: string[]): boolean {
    if (userConnections.length === 0) return true;
    for (const service of userConnections) {
      if (servicesRequired.includes(service.service) && !service.isConnected) {
        return true;
      }
    }
    return false;
  }

  const contextValue: OnboardingContextType = {
    isOpen,
    handleIsOpen,
    handleOpenClose,
    isServiceConnected,
    handleFinishTutorial,
    userConnections,
    checkServices,
    shopifyStores,
  };

  return (
    <OnboardingContext.Provider value={contextValue}>
      <ProcessingLoading isLoading={isOpen.isLoading} />
      <Outlet />
    </OnboardingContext.Provider>
  );
};

export default OnboardingInitial;
