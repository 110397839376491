import { PayloadAction } from "@reduxjs/toolkit";
import { MouseTooltipState, TEdtiorUIState } from "../types";

/**
 * Adds a mouseMouseTooltip to the array.
 * If a mouseMouseTooltip with the same position exists, it will be overwritten.
 */
export const addMouseTooltip = (
  state: TEdtiorUIState,
  action: PayloadAction<MouseTooltipState>,
) => {
  state.mouseMouseTooltips = action.payload;
};
