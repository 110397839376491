import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EEditorConfig, EEditorModes } from "./types";
import { createSelector } from "reselect";
import { StoreState } from "../../Types";

export const EDITOR_CONFIG_PREFIX = "EDITOR_CONFIG";
export const EDITOR_CONFIG_KEY = "EDITOR_CONFIG";

const initialState: EEditorConfig = {
  currentEditorMode: EEditorModes.NORMAL,
  selectedItemID: null,
  backgroundColor: "rgb(255,255,255)",
  aspectRatio: 21 / 9,
  metadata: null,
};

export const editorConfigDataSlice = createSlice({
  name: EDITOR_CONFIG_PREFIX,
  initialState,
  reducers: {
    // The action now accepts a PayloadAction to specify the new mode directly.
    changeMode: (state, action: PayloadAction<EEditorModes>) => {
      // Update the current editor mode with the new mode provided in the action payload.
      state.currentEditorMode = action.payload;
    },
    selectGlobalItem: (state, action: PayloadAction<string | null>) => {
      // Update the current editor mode with the new mode provided in the action payload.
      state.selectedItemID = action.payload;
    },
    changeBackgroundColor: (state, action: PayloadAction<string>) => {
      state.backgroundColor = action.payload;
    },
    setAspectRatio: (state, action: PayloadAction<number>) => {
      state.aspectRatio = action.payload;
    },

    changeTemplateMetaData: (state, action: PayloadAction<any>) => {
      state.metadata = action.payload;
    },
  },
});

// Exporting the reducer to be used in store configuration
const editorConfigDataReducer = editorConfigDataSlice.reducer;

export const getCurrentGlobalItem = (state: StoreState) => {
  return state.editorConfigDataReducer.selectedItemID;
};

// Selector to retrieve the current editor mode from the state
export const selectCurrentEditorMode = (state: StoreState) =>
  state.editorConfigDataReducer.currentEditorMode;
// Selector to retrieve the current editor mode from the state

export const selectCurrentBackgroundColor = (state: StoreState) =>
  state.editorConfigDataReducer.backgroundColor;

export const getTemplateMetada = (state: StoreState) =>
  state.editorConfigDataReducer.metadata;

export const getAspectRatio = (state: StoreState) =>
  state.editorConfigDataReducer.aspectRatio;

// Memoized selector for editor modes
export const editorModes = createSelector(
  [selectCurrentEditorMode],
  (currentEditorMode) => ({
    normal: currentEditorMode === EEditorModes.NORMAL,
  }),
);

// Exporting the actions so they can be dispatched in the application
export const editorConfigAction = editorConfigDataSlice.actions;

export default editorConfigDataReducer;
